import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'

class ServicesTwo extends React.Component {

    shouldComponentUpdate() {
        return false
    }
      
    render() {

        const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #111;
            margin-top: 220px;
            padding: 100px 0 50px 0;
            .heading {
                width: 100%;
            }
          }
        `

        const ServiceRow = styled(Row)`
            margin-top: 100px;
        `

         const ServiceHeading = styled.h4`
            font-size: 22px;
            font-weight: 500;
            font-family: Teko;
            color: #fff;
            margin-bottom: 0px;
        `

        const ServiceSeparator = styled.div`
            height: 2px;
            width: 50px;
            background-color: #24e5af;
            margin-bottom: 5px;
         `

         const ServiceNumber = styled.h5`
            position: absolute;
            font-size: 60px;
            color: #fff;
            font-weight: 900;
            top: -50px;
            opacity: .1;
            z-index: -1;
            transition: .2s;
         `

         const ServiceElement = styled.div`
            margin-bottom: 90px;
            border-radius: 20px;
            transition: .2s;
            position: relative;
            &:hover ${ServiceNumber} {
                opacity: .3 !important;
                color: #24e5af;
            }
        `

         const ServiceText = styled.p`
            font-size: 11px;
            font-weight: 300;
            color: #c5c5c5;
            padding-right: 25px;
         `


        return(
            <Section id="processen">
                <Container>
                    <AnimatedHeading text="Sådan foregår det" />
                    <ServiceRow>
                        <Col md={3}>
                            <AnimationContainer animation="fadeInDown" delay={500}>
                                    <ServiceElement>
                                        <ServiceNumber>
                                            01
                                        </ServiceNumber>
                                        <ServiceHeading>
                                            Du kontakter os
                                        </ServiceHeading>
                                        <ServiceSeparator/>
                                        <ServiceText>
                                            Du beskriver din ide i korte træk og sender dine kontaktinformationer.
                                            Vi vender tilbage og finder sammen en dato, hvor vi kan tage en uforpligtende snak om din ide, på et fysisk eller online møde.
                                        </ServiceText>
                                    </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={3}>
                            <AnimationContainer animation="fadeInDown"  delay={100}>
                                <ServiceElement>
                                    <ServiceNumber>
                                        02
                                    </ServiceNumber>
                                    <ServiceHeading>
                                        Vi holder et uforpligtende møde
                                    </ServiceHeading>
                                    <ServiceSeparator/>
                                    <ServiceText>
                                        På dette møde fortæller du om din ide og vi præsenterer os selv og hvorfor vi kan hjælpe dig.
                                        Dette møde handler lige så meget om sparring, som det handler om præsentation af din case.
                                    </ServiceText>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={3}>
                            <AnimationContainer animation="fadeInDown" delay={1500}>
                                <ServiceElement>
                                    <ServiceNumber>
                                        03
                                    </ServiceNumber>
                                    <ServiceHeading>
                                        Vi estimerer projektet og udvikler prototype
                                    </ServiceHeading>
                                    <ServiceSeparator/>
                                    <ServiceText>
                                        Udfra de informationer vi fik på mødet, estimerer vi varigheden og prisen for udviklingen af din ide. 
                                        Vi vil samtidig udvikle en prototype som har til formål at give dig et billede af hvordan appen vil se ud.
                                    </ServiceText>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={3}>
                            <AnimationContainer animation="fadeInDown" delay={2000}>
                                <ServiceElement>
                                    <ServiceNumber>
                                        04
                                    </ServiceNumber>
                                    <ServiceHeading>
                                        Tilbuddet præsenteres og aftale indgås
                                    </ServiceHeading>
                                    <ServiceSeparator/>
                                    <ServiceText>
                                        Du får på mødet lov til at klikke rundt i prototypen og vi præsenterer vores estimater og tanker omkring projektet.
                                        På dette møde vil vi også gennemgå kontrakten i fællesskab.
                                    </ServiceText>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={3}>
                            <AnimationContainer animation="fadeInUp" delay={500}>
                                <ServiceElement>
                                    <ServiceNumber>
                                        05
                                    </ServiceNumber>
                                    <ServiceHeading>
                                        Udviklingen begynder med løbende statusmøder
                                    </ServiceHeading>
                                    <ServiceSeparator/>
                                    <ServiceText>
                                        Vi påbegynder udviklingen og vil løbende reportere til dig. 
                                        På den måde er du med under hele udviklingen og kan frit lufte dine tanker omkring produktet undervejs.
                                    </ServiceText>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={3}>
                            <AnimationContainer animation="fadeInUp" delay={100}>
                                <ServiceElement>
                                    <ServiceNumber>
                                        06
                                    </ServiceNumber>
                                    <ServiceHeading>
                                        Test og tilpasning af det færdige produkt
                                    </ServiceHeading>
                                    <ServiceSeparator/>
                                    <ServiceText>
                                        Vi vil undervejs i udviklingen afholde interne tests af produktet.
                                        Når udviklingen er slut vil du have mulighed for at teste produktet og komme med feedback, hvor vi vil tilpasse produktet herefter.
                                    </ServiceText>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={3}>
                            <AnimationContainer animation="fadeInUp" delay={1500}>
                                <ServiceElement>
                                    <ServiceNumber>
                                        07
                                    </ServiceNumber>
                                    <ServiceHeading>
                                        Produktet udgives
                                    </ServiceHeading>
                                    <ServiceSeparator/>
                                    <ServiceText>
                                        Vi diskuterer de sidste detaljer og kan give en estimeret dato for release.
                                        Produktet udgives og din ide er nu blevet til virkelighed.
                                    </ServiceText>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={3}>
                            <AnimationContainer animation="fadeInUp" delay={2000}>
                                <ServiceElement>
                                    <ServiceNumber>
                                        08
                                    </ServiceNumber>
                                    <ServiceHeading>
                                        Vi drifter og videreudvikler efter dine ønsker
                                    </ServiceHeading>
                                    <ServiceSeparator/>
                                    <ServiceText>
                                        Samarbejdet fortsætter og vi vedligeholder dit produkt, ved foreksempel iOS- og Android opdateringer.
                                        Vi er klar til sparring omkring videreudvikling og iværksættelse af nye ideer til dit produkt.
                                    </ServiceText>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                    </ServiceRow>
                </Container>
            </Section>
        )
    }

}

export default ServicesTwo